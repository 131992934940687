:root {
	--collections-node-border-color: hsla(0, 0%, 78%, 0.5);
	--collections-node-border-style: solid;
}

/* Files */
.module-files .collection a {
	border-bottom: none;
}

.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1rem 0px 0px 0px;
	padding: 1rem 0px 0px 0px;
	border-top: 1px solid #a6b0c0;
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #cccccc;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-side-col .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}


/* FAQ */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 10px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid #a6b0c0;
}

/* News */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
	border-top: 1px solid #a6b0c0;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}

/* Partners */
#partners_module.cms_list .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid #a6b0c0;
}

#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* Posts */
.posts-collection > .posts-node {
	border-top: #a6b0c0 solid 1px;
	margin: 1rem 0 0 0;
	padding: 1rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #ce3e16;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #a6b0c0;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button svg {
	margin-top: 3.8px;
}

.right-side-col .box.posts-blocks a {
	border-bottom: 0px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-side-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 45px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 10px 12px 8px !important;
}


/* Submission Forms */
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 1px;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
	font-weight: 700;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	font-weight: normal;
}

/* Services */
@media (max-width: 991px) {
	#services_module.entity .image {
		float: none;
		margin-right: 0px;
	}
}

/** Solutions **/
.module-solutions.controller-pages-categories.action-index h1.page-title {
	margin-bottom: 0rem;
	color: #242425;
	font-size: 2rem;
	font-weight: 400;
	margin-top: 1.25rem;
	line-height: 2.375rem;
}

.module-solutions.controller-pages-solutions.action-show .inside-page-left-column {
	display: none;
}

#solutions-module.collection.solutions-collection {
	border-top: 0px;
	margin-top: 0;
	margin-bottom: 5rem;
	padding-top: 0px;
}

#solutions-module.collection .node {
	border-top: 1px solid hsla(0, 0%, 78%, 0.5);
	border-bottom: 0px !important;
	margin-top: 2.75rem;
	padding-top: 2.55rem;
	margin-bottom: 0px;
	padding-bottom: 0px;
}

#solutions-module.collection .node .solution-node-summary h3 {
	margin-bottom: 1rem;
	margin-top: 0px;
	font-size: 1.75rem;
	line-height: 2.375rem;
	font-weight: 400;
	text-decoration: none;
}

#solutions-module.collection .node .solution-node-summary h3 a {
	border-bottom: 1px solid #ce3e16;
	color: #616163;
	font-size: inherit;
	line-height: inherit;
	font-weight: inherit;
	text-decoration: none;
}

#solutions-module.collection .node .solution-node-summary h3 a:hover {
	border-bottom-color: transparent;
	color: #ce3e16 !important;
}

#solutions-module.collection .node .solution-node-summary .solution-node-summary-content {
	margin-bottom: 10px;
}

#solutions-module.collection .node .solution-node-summary .solution-node-summary-more-details {
	color: #707785;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 700;
	letter-spacing: 0.05px;
	text-decoration: none;
	text-transform: uppercase;
}

#solutions-module.collection .node .solution-node-summary .solution-node-summary-more-details:hover {
	color: #ce3e16;
}

#solutions-module.collection .node .solution-node-summary .button {
	padding-right: 0rem;
	padding-left: 2rem;
	background-image: url('/assets/images/gray-link-arrow.svg');
	background-position: 0% 50%;
	background-repeat: no-repeat;
	color: #707785 !important;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 700;
	letter-spacing: 0.05px;
	text-decoration: none;
	text-transform: uppercase;
	background-color: transparent;
	border: 0;
	box-shadow: none;
	padding-top: 3px;
	padding-bottom: 3px;
	height: auto;
	margin: 0;
}

#solutions-module.collection .node .solution-node-summary .button:hover {
	background-image: url('/assets/images/red-link-arrow.svg');
	color: #ce3e16 !important;
}

.solution-category-intro p:last-child {
	margin-bottom: 0px;
}

.solution-category-intro {
	margin-top: 2rem;
}

.module-solutions.controller-pages-categories.action-show .page-title,
.module-solutions.controller-pages-categories.action-show h1.page-title {
	display: none;
}

.module-solutions.controller-pages-categories.action-show h2 {
	margin-bottom: 0rem;
	color: #242425;
	font-size: 2rem;
	font-weight: 400;
	margin-top: 1.25rem;
	line-height: 2.375rem;
}

.module-solutions.controller-pages-solutions.action-show .solution-card-footer {
	display: none !important;
}

@media screen and (max-width: 479px) {
	#solutions-module.collection .node {
		margin-top: 2rem;
		padding-top: 2rem;
	}

	#solutions-module.collection .node .solution-node-summary {
		text-align: left;
	}

	#solutions-module.collection .node .solution-node-summary h3 {
		text-align: center;
	}
}

/*  Solution details page  */
.solution-feature-node-divider {
	background-image: url("/assets/images/solutions-divider-slant.svg");
	background-position: 0px 0px;
	background-size: cover;
	height: 110px;
	margin-bottom: -1px;
}

.solution-feature-node.even + .solution-feature-node-divider {
	margin-bottom: 0px;
	margin-top: -1px;
	transform: rotate(180deg);
}

#solutions-module.node {
	--collections-node-border-width: 0;
}

#solutions-module.node .collection.solutions-features .node {
	display: block;
	margin: 0 auto;
	max-width: 1500px;
	padding: 0 0rem;
	position: relative;
	z-index: 1;
}

.solutions-features.single .solution-feature-node::before {
	content: " ";
	display: block;
	background-color: #fff;
	background-image: url("/assets/images/solutions-divider-slant.svg");
	background-position: 0px 0px;
	background-size: cover;
	height: 110px;
	margin-bottom: -1px;
}

.solutions-features.single .solution-feature-node::after {
	content: " ";
	display: block;
	background-color: #fff;
	background-image: url("/assets/images/solutions-divider-slant.svg");
	background-position: 0px 0px;
	background-size: cover;
	height: 110px;
	margin-bottom: -1px;
	transform: rotate(180deg);
}

.solutions-features.single .solution-feature-node-divider {
	display: none;
}

.solutions-features.multiple .node.solution-feature-node.odd.last + .solution-feature-node-divider {
	display: none;
}

#solutions-module.node .collection.solutions-features.single .node,
#solutions-module.node .collection.solutions-features.multiple .node.even {
	background-color: rgba(230, 235, 245, 0.2);
}

#solutions-module.node .collection.solutions-features.single .solution-card-body-description,
#solutions-module.node .collection.solutions-features.multiple .node.even .solution-card-body-description {
	background-color: rgba(230, 235, 245, 0.65);
}

.module-solutions.controller-pages-solutions.action-show .page-title {
	display: none;
}

.module-solutions.controller-pages-solutions.action-show .inside-page-container,
.module-solutions.controller-pages-solutions.action-show .container {
	width: 100%;
	max-width: none;
	padding-left: 0px;
	padding-right: 0px;
	margin-left: 0px;
	margin-right: 0px;
}

.module-solutions.controller-pages-solutions.action-show .breadcrumbs-top-navigation,
.module-solutions.controller-pages-solutions.action-show .bfc,
.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-cards,
.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-overview,
.module-solutions.controller-pages-solutions.action-show .example {
	max-width: 1500px;
	padding-left: 4rem;
	padding-right: 4rem;
	margin-left: auto;
	margin-right: auto;
}

.module-solutions.controller-pages-solutions.action-show .hr-divider.hr-divider-long {
	padding-left: 0;
	padding-right: 0;
	margin-left: auto;
	margin-right: auto;
	width: calc(100% - 8rem);
	max-width: calc(1500px - 8rem);
}

#solutions-module.node .solution-overview .solution-intro {
	width: 100%;
}

#solutions-module.node .collection.solutions-features .node {
	max-width: none;
	padding-left: 0px;
	padding-right: 0px;
	margin-left: 0px;
	margin-right: 0px;
}

.solution-feature-node .solution-feature-node-title h3 {
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	font-size: 1.75rem;
	line-height: 2.375rem;
	color: #242425;
	font-weight: 400;
	text-align: left;
}

#solutions-module.node .solution-cards .solution-card-header .solution-card-header-title {
	color: #616163;
}

#solutions-module.node .solution-cards .solution-card-header .solution-card-header-title strong {
	font-weight: 600;
}

#solutions-module.node .solution-cards .solution-card-header {
	margin-bottom: 0px;
	margin-top: 1rem;
}

#solutions-module.node .example,
.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo {
	text-align: center;
}

.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo {
	margin-top: 4rem;
}

#solutions-module.node .example h2,
.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo h2 {
	color: #242425;
	font-weight: 400;
	text-transform: capitalize;
}

#solutions-module.node .example p,
.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo p {
	margin-bottom: 1.5rem;
}

#solutions-module.node .example p a,
.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo p a {
	border-bottom: 1px solid #ce3e16;
	color: #242425;
	text-decoration: none;
}

.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo p .button a {
	font-weight: 700;
}

#solutions-module.node .example p a:hover,
.module-solutions.controller-pages-categories.action-show .solution-category-additionalInfo p a:hover {
	color: #ce3e16;
}

@media screen and (max-width: 991px) {
	.solution-feature-node .solution-feature-node-title h3 {
		margin-top: 0rem;
	}

	.module-solutions.controller-pages-solutions.action-show .breadcrumbs-top-navigation,
	.module-solutions.controller-pages-solutions.action-show .bfc,
	.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-cards,
	.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-overview,
	.module-solutions.controller-pages-solutions.action-show .example {
		padding-left: 2.5rem;
		padding-right: 2.5rem;
	}

	.module-solutions.controller-pages-solutions.action-show .hr-divider.hr-divider-long {
		width: calc(100% - 5rem);
		max-width: calc(1500px - 5rem);
	}
}

@media screen and (max-width: 767px) {
	.module-solutions.controller-pages-solutions.action-show .breadcrumbs-top-navigation,
	.module-solutions.controller-pages-solutions.action-show .bfc,
	.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-cards,
	.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-overview,
	.module-solutions.controller-pages-solutions.action-show .example {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.module-solutions.controller-pages-solutions.action-show .hr-divider.hr-divider-long {
		width: calc(100% - 4rem);
		max-width: calc(1500px - 4rem);
	}

	#solutions-module.node .solution-cards .solution-cards-column.content .solution-cards-container .solution-card .solution-card-body {
		max-height: unset !important;
	}

	#solutions-module.node .example h2 {
		margin-bottom: 0.25rem;
	}

	#solutions-module.node .solution-overview .solution-image {
		margin-bottom: 20px;
		margin-right: auto;
		margin-left: auto;
	}
}

@media screen and (max-width: 479px) {
	.module-solutions.controller-pages-solutions.action-show .breadcrumbs-top-navigation,
	.module-solutions.controller-pages-solutions.action-show .bfc,
	.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-cards,
	.module-solutions.controller-pages-solutions.action-show #solutions-module.node .solution-overview,
	.module-solutions.controller-pages-solutions.action-show .example {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.module-solutions.controller-pages-solutions.action-show .hr-divider.hr-divider-long {
		width: calc(100% - 2.5rem);
		max-width: calc(1500px - 2.5rem);
	}
}


/**************** Content Boxes ********************/
/***************************************************/
/* files */

/* news */
.cta-bar-header #news_module.homepage_contentbox {
	font-size: 1rem;
	line-height: 1.5rem;
}

#news_module.homepage_contentbox .cms_list_item {
	margin-bottom: 20px;
}

.cta-bar-header #news_module.homepage_contentbox h3,
.cta-bar-header #news_module.homepage_contentbox a,
.cta-bar-header #news_module.homepage_contentbox a:hover,
.cta-bar-header #news_module.homepage_contentbox {
	color: #fff;
}

#news_module.homepage_contentbox h3 {
	font-size: 1.2rem;
	line-height: 1.7rem;
	font-weight: normal;
	margin: 0px 0px 5px 0px;
}

.plugin-cb #news_module.homepage_contentbox .cms_description p {
	font-size: 1rem;
	line-height: 1.5rem;
}

/* parnters */
#partners_module.homepage_contentbox,
#partners_module.homepage_contentbox a,
#partners_module.homepage_contentbox a:hover {
	font-size: 1rem;
	line-height: 1.5rem;
	color: #000000;
}

.cta-bar-header #partners_module.homepage_contentbox,
.cta-bar-header #partners_module.homepage_contentbox a,
.cta-bar-header #partners_module.homepage_contentbox a:hover,
.cta-bar-header #partners_module.homepage_contentbox.displaymode_list .cms_list_item .cms_metadata1.cms_title .cms_content {
	color: #fff;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
	margin-bottom: 10px;
}


/* posts */
.cta-bar-header .collection.posts-collection {
	font-size: 1rem;
	line-height: 1.5rem;
	color: #fff;
}

.portfolio-text-wrapper .collection.posts-collection,
.portfolio-text-wrapper .collection.posts-collection a,
.portfolio-text-wrapper .collection.posts-collection a:hover {
	color: #000;
	font-size: 1rem;
	line-height: 1.5rem;
}

.plugin-cb .collection.posts-collection p {
	font-size: 1rem;
	line-height: 1.5rem;
}


/* form */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	padding: 10px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.87rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

.cta-bar-header #submissionforms_module.homepage_contentbox {
	color: #fff;
}



/*** PORTFOLIOS ***/
.breadcrumbs-top-navigation {
	clear: both;
	overflow: hidden;
}

.breadcrumbs-top-navigationnav {
	display: inline-block;
	float: left;
}

.portfolio-top-navigation {
	display: flex;
	width: 45%;
	margin-top: -3px;
	margin-left: 100px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.breadcrumbs-top-navigation.show-on-mobile {
	display: inline-block;
	width: 100%;
	overflow: visible;
	position: relative;
}

.div-block-13 {
	margin-right: 10px;
}

.text-block-7 {
	padding-top: 3px;
}

.dropdown.topnavigation {
	display: block;
	width: auto;
	margin-top: 0px;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-radius: 50px;
	background-color: rgba(169, 178, 184, 0.5);
	background-image: url("/20160907154343/assets/images/arrow-icon-blue-01.png");
	background-position: 90% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	font-size: 0.9em;
	position: static;
	line-height: 1.15em;
}

.dropdown.topnavigation.active {
	background-color: #d43f16;
	background-image: url("/20160907154343/assets/images/arrow-icon-01.png");
}

.topnavigation-link,
.topnavigation-link:hover,
.topnavigation.open .topnavigation-link {
	display: block;
	margin-top: -2px;
	padding: 9px 35px 6px 15px;
	color: #0f486f;
	font-size: 1em;
	line-height: 1.15em;
	font-weight: 400;
	letter-spacing: 1px;
	text-decoration: none;
	border-bottom: none !important;
}

.dropdown.topnavigation.active .topnavigation-link,
.dropdown.topnavigation.active .topnavigation-link:hover,
.topnavigation.open.active .topnavigation-link {
	color: #ffffff;
}

.topnavigation-dropdown-list {
	left: auto;
	right: 0;
	z-index: 1000;
	overflow: hidden;
	width: auto;
	height: auto;
	padding-top: 1.05rem;
	-webkit-transition: all 400ms ease;
	transition: all 400ms ease;
	color: #fff;
	opacity: 0;
	position: absolute;
	transition: opacity 200ms ease 0s;
}

.dropdown.topnavigation:hover .topnavigation-dropdown-list,
.topnavigation.open .topnavigation-dropdown-list {
	opacity: 1;
	width: 100%;
}

.topnavigation-dropdown-list .topnavigation-dropdown-list-inner {
	background-color: #353738;
	box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
	display: none;
	max-width: 1100px;
}

.dropdown.topnavigation:hover .topnavigation-dropdown-list .topnavigation-dropdown-list-inner,
.topnavigation.open .topnavigation-dropdown-list .topnavigation-dropdown-list-inner {
	display: block;
}

.or-text-block {
	margin-right: 10px;
	margin-left: 10px;
}

.text-block-8 {
	padding-top: 3px;
}

.portfolio-top-navigation div.or-text-block:last-child {
	display: none;
}

.topnavigation-dropdown-list-inner ul {
	margin: 0px;
	padding: 0px;
	columns: 3;
	width: 100%;
	list-style-type: none;
}

.topnavigation-dropdown-list-inner ul li {
	margin: 0px;
	padding: 0px;
	width: 100%;
}

.topnavigation-dropdown-list-inner ul li a {
	display: inline-block;
	width: 100%;
	padding: 15px 20px;
	color: #ffffff;
	font-size: 0.9rem;
	line-height: 1.25em;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
	border-bottom: 1px solid #ce3e16;
}

.topnavigation-dropdown-list-inner ul li a:hover,
.topnavigation-dropdown-list-inner ul li a.w--current {
	background-color: #565e64;
	color: #ffffff;
}

#portfolio_module.cms_entity .grid.inside-page .grid-box.half.inside-page {
	display: inline-flex;
}

#portfolio_module.cms_entity .portfolio-side .secondary {
	padding: 8px 10px;
}

.portfolio-top-navigation .text-block-7,
.portfolio-top-navigation .text-block-8 {
	font-size: 0.8rem;
}

#portfolio_module.cms_entity .inside-page-banner.two {
	position: relative;
}

#portfolio_module.cms_entity .left_wrapper {
	margin-top: 20px;
}

#portfolio_module.cms_entity .portfolio-side .secondary {
	display: inline-block;
}

#portfolio_module.cms_entity .portfolio-section.websiteDiv a {
	white-space: normal;
	margin: 0;
	min-width: auto;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
	height: auto;
}

.portfolio-top-navigation {
	width: 55%;
	margin-top: -5px;
}

.breadcrumbs-top-navigation.show-on-mobile nav {
	width: 45%;
	float: left;
}

@media screen and (max-width: 991px) {
	.breadcrumbs-top-navigation.show-on-mobile nav {
		width: calc(100% - 363px);
	}

	.portfolio-top-navigation {
		width: 363px;
		margin-left: 0px;
	}
}

@media screen and (max-width: 767px) {
	.breadcrumbs-top-navigation.show-on-mobile {
		padding-bottom: 20px;
	}

	.breadcrumbs-top-navigation nav {
		width: 100%;
		margin-bottom: 0;
		float: none;
	}

	.portfolio-top-navigation {
		width: 100% !important;
		-webkit-box-pack: start;
		-webkit-justify-content: flex-start;
		-ms-flex-pack: start;
		justify-content: flex-start;
	}

	.breadcrumbs-top-navigation.show-on-mobile nav {
		width: 100% !important;
	}

	.topnavigation-dropdown-list {
		padding-top: 20px;
	}

	.topnavigation-dropdown-list-inner ul {
		columns: 1;
	}
}

@media screen and (max-width: 479px) {
	.div-block-13 {
		display: none;
	}

	.breadcrumbs-top-navigation.show-on-mobile {
		padding-bottom: 20px;
	}
}
/*** END PORTFOLIOS ***/

/* gdpr */
#cookie-consent-container .consent-actions {
	width: 390px;
}

#cookie-consent-container .consent-disclaimer {
	width: calc(100% - 400px);
	margin-bottom: 15px;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	flex-direction: row;
}

#cookie-consent-form-container form {
	top: 10rem;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
}

#cookie-consent-form-container form .consent-header {
	text-align: center;
}

#cookie-consent-container .consent-actions .consent-actions-container {
	align-items: center;
}

.consent-form-visible .navbar-container {
	display: none;
}

.consent-form-visible.mm-wrapper,
.consent-form-visible .mm-page {
	position: static;
}

#cookie-consent-container.minimized {
	bottom: 5px;
	right: 5rem;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	font-weight: normal;
}

@media (max-width: 991px) {
	#cookie-consent-container .consent-actions {
		width: 100%;
		order: 2;
	}

	#cookie-consent-container .consent-disclaimer {
		width: 100%;
	}

	#cookie-consent-container {
		display: flex;
		flex-direction: column;
	}

	#cookie-consent-container .consent-dismiss-trigger-container {
		position: absolute;
		top: 10px;
		left: auto;
		right: 0;
	}
}

@media screen and (max-width: 479px) {
	#cookie-consent-container .consent-actions a.consent-all-trigger,
	#cookie-consent-container .consent-actions a.reject-all-trigger {
		font-size: 0.8rem;
	}

	#cookie-consent-container .consent-actions,
	#cookie-consent-container .consent-disclaimer,
	#cookie-consent-container .consent-disclaimer p {
		line-height: 1.4rem;
	}
}
